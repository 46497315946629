const data = JSON.stringify({
  total_pages: 6,
  pages: [
    {
      id: 1,
      page_nr: 1,
      subtitle: 'Dynamique',
      description: '',
      questions: [
        {
          id: 999,
          nr: 999,
          show_id: false,
          question: "Indiquez si vous avez noté des changements dans vos symptômes depuis que vous avez rempli le questionnaire pour la première fois (partie A) ? (Cochez la réponse la plus appropriée)",
          answers: [
            {
              id: 1,
              desc: "",
              name: "Oui, je me sens comme avant (Tous les symptômes ont disparu)",
              points: 0
            },
            {
              id: 2,
              desc: "",
              name: "Oui, je me sens mieux (La plupart des symptômes ont disparu)",
              points: 1
            },
            {
              id: 3,
              desc: "",
              name: "Oui, je me sens un peu mieux (Seulement quelques symptômes ont disparu)",
              points: 2
            },
            {
              id: 4,
              desc: "",
              name: "Non, il n'y a presque pas de changement (J’ai toujours quasiment les même symptômes)",
              points: 3
            },
            {
              id: 5,
              desc: "",
              name: "Oui, je me sens moins bien (Mes symptômes sont pires que la fois précédente)",
              points: 4
            }
          ]
        },
      ]
    },
    {
      id: 2,
      page_nr: 2,
      subtitle: 'Symptômes Typiques',
      description: 'Veuillez indiquer si vous avez noté les symptômes suivants au cours des dernières 24 heures, ainsi que leur intensité',
      questions: [
        {
          id: 1,
          nr: 1,
          show_id: true,
          question: "Envies d’uriner très fréquentes avec de petits volumes d’urine (visites fréquentes aux toilettes)",
          answers: [
            {
              id: 1,
              desc: "jusqu'à 4 fois par jour",
              name: "Non",
              points: 0
            },
            {
              id: 2,
              desc: "5-6 fois par jour",
              name: "Oui, faible",
              points: 1
            },
            {
              id: 3,
              desc: "7-8 fois par jour",
              name: "Oui, modéré",
              points: 2
            },
            {
              id: 4,
              desc: "9-10 fois par jour et plus",
              name: "Oui, fort",
              points: 3
            }
          ]
        },
        {
          id: 2,
          nr: 2,
          show_id: true,
          question: "Besoin urgent d’uriner (fort et incontrôlable)",
          answers: [
            {
              id: 1,
              desc: "",
              name: "Non",
              points: 0
            },
            {
              id: 2,
              desc: "",
              name: "Oui, faible",
              points: 1
            },
            {
              id: 3,
              desc: "",
              name: "Oui, modéré",
              points: 2
            },
            {
              id: 4,
              desc: "",
              name: "Oui, fort",
              points: 3
            }
          ]
        },
        {
          id: 3,
          nr: 3,
          show_id: true,
          question: "Sensation douloureuse de brûlure en urinant",
          answers: [
            {
              id: 1,
              desc: "",
              name: "Non",
              points: 0
            },
            {
              id: 2,
              desc: "",
              name: "Oui, faible",
              points: 1
            },
            {
              id: 3,
              desc: "",
              name: "Oui, modéré",
              points: 2
            },
            {
              id: 4,
              desc: "",
              name: "Oui, fort",
              points: 3
            }
          ]
        },
        {
          id: 4,
          nr: 4,
          show_id: true,
          question: "Sensation de vidange incomplète de la vessie (toujours envie d’uriner après avoir été aux toilettes)",
          answers: [
            {
              id: 1,
              desc: "",
              name: "Non",
              points: 0
            },
            {
              id: 2,
              desc: "",
              name: "Oui, faible",
              points: 1
            },
            {
              id: 3,
              desc: "",
              name: "Oui, modéré",
              points: 2
            },
            {
              id: 4,
              desc: "",
              name: "Oui, fort",
              points: 3
            }
          ]
        },
        {
          id: 5,
          nr: 5,
          show_id: true,
          question: "Douleur dans le bas-ventre (région suspubienne), en dehors du fait d'uriner",
          answers: [
            {
              id: 1,
              desc: "",
              name: "Non",
              points: 0
            },
            {
              id: 2,
              desc: "",
              name: "Oui, faible",
              points: 1
            },
            {
              id: 3,
              desc: "",
              name: "Oui, modéré",
              points: 2
            },
            {
              id: 4,
              desc: "",
              name: "Oui, fort",
              points: 3
            }
          ]
        },
        {
          id: 6,
          nr: 6,
          show_id: true,
          question: "Sang visible dans les urines (en dehors des règles)",
          answers: [
            {
              id: 1,
              desc: "",
              name: "Non",
              points: 0
            },
            {
              id: 2,
              desc: "",
              name: "Oui, faible",
              points: 1
            },
            {
              id: 3,
              desc: "",
              name: "Oui, modéré",
              points: 2
            },
            {
              id: 4,
              desc: "",
              name: "Oui, fort",
              points: 3
            }
          ]
        },
      ]
    },
    {
      id: 3,
      page_nr: 3,
      subtitle: 'Symptômes Différentiels',
      description: 'Veuillez indiquer si vous avez noté les symptômes suivants au cours des dernières 24 heures, ainsi que leur intensité',
      questions: [
        {
          id: 7,
          nr: 7,
          show_id: true,
          question: "Douleur lombaire au niveau du rein (normalement d’un seul côté du dos)",
          answers: [
            {
              id: 1,
              desc: "",
              name: "Non",
              points: 0
            },
            {
              id: 2,
              desc: "",
              name: "Oui, faible",
              points: 1
            },
            {
              id: 3,
              desc: "",
              name: "Oui, modéré",
              points: 2
            },
            {
              id: 4,
              desc: "",
              name: "Oui, fort",
              points: 3
            }
          ]
        },
        {
          id: 8,
          nr: 8,
          show_id: true,
          question: "Perte vaginale anormale (quantité, couleur et/ou odeur)",
          answers: [
            {
              id: 1,
              desc: "",
              name: "Non",
              points: 0
            },
            {
              id: 2,
              desc: "",
              name: "Oui, faible",
              points: 1
            },
            {
              id: 3,
              desc: "",
              name: "Oui, modéré",
              points: 2
            },
            {
              id: 4,
              desc: "",
              name: "Oui, fort",
              points: 3
            }
          ]
        },
        {
          id: 9,
          nr: 9,
          show_id: true,
          question: "Écoulements purulents de l'urètre (en dehors du fait d'uriner)",
          answers: [
            {
              id: 1,
              desc: "",
              name: "Non",
              points: 0
            },
            {
              id: 2,
              desc: "",
              name: "Oui, faible",
              points: 1
            },
            {
              id: 3,
              desc: "",
              name: "Oui, modéré",
              points: 2
            },
            {
              id: 4,
              desc: "",
              name: "Oui, fort",
              points: 3
            }
          ]
        },
        {
          id: 10,
          nr: 10,
          show_id: true,
          question: "Sensation de température élevée / fièvre",
          answers: [
            {
              id: 1,
              desc: "≤37.5 C",
              name: "Non",
              points: 0
            },
            {
              id: 2,
              desc: "37.6-37.9 C",
              name: "Oui, faible",
              points: 1
            },
            {
              id: 3,
              desc: "38.0-38.9 C",
              name: "Oui, modéré",
              points: 2
            },
            {
              id: 4,
              desc: "≥39.0 C",
              name: "Oui, fort",
              points: 3
            }
          ]
        },
      ]
    },
    {
      id: 4,
      page_nr: 4,
      subtitle: 'Qualité de vie',
      description: '',
      questions: [
        {
          id: 11,
          nr: 11,
          show_id: true,
          question: "Veuillez indiquer l'importance de la gêne causée par ces symptômes au cours des dernières 24 heures (Cochez la réponse la plus appropriée)",
          answers: [
            {
              id: 1,
              desc: "",
              name: "Aucune gêne (Pas de symptôme, je me sens aussi bien que d'habitude)",
              points: 0
            },
            {
              id: 2,
              desc: "",
              name: "Gêne faible (Je me sens un peu moins bien que d'habitude)",
              points: 1
            },
            {
              id: 3,
              desc: "",
              name: "Gêne modérée (Je me sens beaucoup moins bien que d'habitude)",
              points: 2
            },
            {
              id: 4,
              desc: "",
              name: "Gène très forte (Je me sens très mal)",
              points: 3
            },
          ]
        },
        {
          id: 12,
          nr: 12,
          show_id: true,
          question: " Veuillez indiquer dans quelle mesure ces symptômes ont modifié vos activités quotidiennes / votre travail au cours des dernières 24 heures (Cochez la réponse la plus appropriée)",
          answers: [
            {
              id: 1,
              desc: "",
              name: "Pas du tout modifié (Je travaille comme d'habitude)",
              points: 0
            },
            {
              id: 2,
              desc: "",
              name: "Faiblement modifié (A cause des symptômes, je travaille un peu moins)",
              points: 1
            },
            {
              id: 3,
              desc: "",
              name: "Modérément modifié (Le travail quotidien exige beaucoup d'efforts)",
              points: 2
            },
            {
              id: 4,
              desc: "",
              name: "Fortement modifié (Je ne peux pratiquement pas travailler)",
              points: 3
            },
          ]
        },
        {
          id: 13,
          nr: 13,
          show_id: true,
          question: "Veuillez indiquer dans quelle mesure ces symptômes ont modifié vos activités sociales (visites, rencontres avec les amis, etc.), au cours des dernières 24 heures (Cochez la réponse la plus appropriée)",
          answers: [
            {
              id: 1,
              desc: "",
              name: "Pas du tout modifié (Mes activités n'ont pas changé, je vis comme d'habitude)",
              points: 0
            },
            {
              id: 2,
              desc: "",
              name: "Faiblement modifié (je réalise la plupart des activités sociales)",
              points: 1
            },
            {
              id: 3,
              desc: "",
              name: "Modérément modifié (j'ai réduit mes activités sociales)",
              points: 2
            },
            {
              id: 4,
              desc: "",
              name: "Fortement modifié (Je n'ai quasiment plus d'activités sociales)",
              points: 3
            },
          ]
        },
      ]
    },
    {
      id: 5,
      page_nr: 5,
      subtitle: 'Supplémentaires',
      description: 'Veuillez indiquer si au moment de remplir le questionnaire vous avez',
      questions: [
        {
          id: 14,
          nr: 14,
          show_id: true,
          question: "Règles (menstruations) ?",
          answers: [
            {
              id: 1,
              desc: "",
              name: "Non",
              points: "no"
            },
            {
              id: 2,
              desc: "",
              name: "Oui",
              points: "yes"
            }
          ]
        },
        {
          id: 15,
          nr: 15,
          show_id: false,
          question: "Syndrome prémenstruel (douleurs avant les règles, douleurs mammaires, irritabilité) ?",
          answers: [
            {
              id: 1,
              desc: "",
              name: "Non",
              points: "no"
            },
            {
              id: 2,
              desc: "",
              name: "Oui",
              points: "yes"
            }
          ]
        },
        {
          id: 16,
          nr: 16,
          show_id: false,
          question: "Symptômes de la ménopause (par exemple : bouffées de chaleur) ?",
          answers: [
            {
              id: 1,
              desc: "",
              name: "Non",
              points: "no"
            },
            {
              id: 2,
              desc: "",
              name: "Oui",
              points: "yes"
            }
          ]
        },
        {
          id: 17,
          nr: 17,
          show_id: false,
          question: "Êtes-vous enceinte ?",
          answers: [
            {
              id: 1,
              desc: "",
              name: "Non",
              points: "no"
            },
            {
              id: 2,
              desc: "",
              name: "Oui",
              points: "yes"
            }
          ]
        },
        {
          id: 18,
          nr: 18,
          show_id: false,
          question: "Diabète diagnostiqué ?",
          answers: [
            {
              id: 1,
              desc: "",
              name: "Non",
              points: "no"
            },
            {
              id: 2,
              desc: "",
              name: "Oui",
              points: "yes"
            }
          ]
        },
      ]
    },
    {
      id: 6,
      page_nr: 6,
      subtitle: '',
      description: ''
    }
  ],
})

export default data
